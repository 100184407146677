import { ChangeEvent, ChangeEventHandler, ComponentPropsWithRef } from 'react';
import styles from './TextInput.module.css';

type TextInputProps = {
  name: string;
  className?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  inputProps?: Omit<ComponentPropsWithRef<'input'>, 'onChange'>;
};

function TextInput(props: TextInputProps) {
  const { name, inputProps, value, onChange, className } = props;

  function onChangeWrapper(e: ChangeEvent<HTMLInputElement>) {
    onChange && onChange(e);
  }

  return (
    <label htmlFor={name} className={`${styles.field} ${className}`}>
      {name}
      <input
        type="text"
        name={name}
        className={styles.input}
        onChange={onChangeWrapper}
        value={value}
        {...inputProps}
      />
    </label>
  );
}

export default TextInput;
